import { useEffect, useState } from "react";
import { useLocation, useNavigationType } from "react-router-dom";

const ScrollToTop = () => {
  const { pathname } = useLocation();
  const navigationType = useNavigationType();
  const [position, setPosition] = useState(0);

  useEffect(() => {
    if (navigationType === "PUSH") {
      setTimeout(() => {
        setPosition(window.scrollY);
        window.scrollTo(0, 0);
      }, 0);
    }
  }, [pathname]);

  useEffect(() => {
    if (navigationType === "POP") {
      setTimeout(() => {
        window.scrollTo(0, position);
      }, 100);
    }
  }, [pathname]);

  return null;
};

export default ScrollToTop;
