import "./App.css";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { Suspense, useEffect, useState, lazy, useLayoutEffect } from "react";
import ForgotPassword from "core/auth/forgot-password/ForgotPassword";
import { UserProvider } from "context/UserContext";

const ProfileRoutes = lazy(() => import("core/profile/router/ProfileRoutes"));
const Login = lazy(() => import("core/auth/login/Login"));
const SignUp = lazy(() => import("core/auth/signup/SignUp"));
const Header = lazy(() => import("core/navbar/header/Header"));
const Footer = lazy(() => import("core/footer/Footer"));
const PracticeRoutes = lazy(() =>
  import("modules/practice-module/router/PracticeRoutes")
);

const SkillTestRoutes = lazy(() =>
  import("modules/skilltest-module/router/SkillTestRoutes")
);
const UserProfileRoutes = lazy(() =>
  import("core/profile/pages/user-profile/UserProfile")
);
const JobfiProfile = lazy(() =>
  import("core/user-profile/pages/jobfi-profile/JobfiProfile")
);

function App() {
  const location = useLocation();
  const [showHeaderFooter, setShowHeaderFooter] = useState(true);
  const [routesLoaded, setRoutesLoaded] = useState(false);

  useEffect(() => {
    setShowHeaderFooter(!["/login", "/signup", "/forgot-password"].includes(location.pathname));
  }, [location.pathname]);

  useLayoutEffect(() => {
    setRoutesLoaded(false);
    setTimeout(() => {
      setRoutesLoaded(true);
    }, 3000);
  }, [location]);

  function NavigateToPractice() {
    const navigate = useNavigate();
    useEffect(() => {
      navigate("/practice", { replace: true });
    }, []);
    return null;
  }

  return (
    <UserProvider>
    <Suspense>
      {showHeaderFooter && <Header />}

      <Routes>
        <Route path="/" element={<NavigateToPractice />} />
        <Route path="practice/*" element={<PracticeRoutes />} />
        <Route path="login" element={<Login />} />
        <Route path="signup" element={<SignUp />} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="skill-test/*" element={<SkillTestRoutes />} />
        <Route path="user-profile" element={<UserProfileRoutes />} />
        <Route path="jobfi-profile" element={<JobfiProfile />} />
        <Route path="profile/*" element={<ProfileRoutes />} />
      </Routes>

      {showHeaderFooter && routesLoaded && <Footer />}
    </Suspense>
    </UserProvider>
  );
}

export default App;
