import React, { useState, useEffect, useRef } from "react";
import "./ForgotPassword.css";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { JobFi } from "assets/icons/practice-module/PracticeModuleIcons";
import * as Yup from "yup";
import { auth_urls } from "../constants/auth-constants-urls";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ForgotPasswordSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email is Required"),
});

const VerifyOTPSchema = Yup.object().shape({
  otp: Yup.string().required("OTP Required"),
});

const NewPasswordSchema = Yup.object().shape({
  password: Yup.string()
    // .min(6, "Password must be at least 6 characters long")
    .required("Password is required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

function ForgotPassword() {
  const [step, setStep] = useState("email");
  const [email, setEmail] = useState("");
  const [otpSent, setOtpSent] = useState(false);
  const [userId, setUserId] = useState("");
  const [otpError, setOtpError] = useState("");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [emailError, setEmailError] = useState("");
  const emailInputRef = useRef(null);

  const handleEmailSubmit = async (email, { setSubmitting, setErrors }) => {
    setLoading(true);
    try {
      const response = await fetch(
        `${auth_urls.forgot_password}?email=${email}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );
      const data = await response.json();

      setUserId(data?.data?.userId);

      if (response.ok) {
        setUserId(data?.data?.userId);
        setLoading(false);
        setEmail(email);
        setOtpSent(true);
        setStep("otp");
        toast.success("OTP sent successfully!");
      } else {
        setLoading(false);
        if (data?.message?.includes("E_0054")) {
          setErrors({ email: "Email Id doesn't exists" });
        }
        setEmailError("User not found" || "This email is not registered.");
      }
    } catch (error) {
      if (error?.includes("E_0054")) {
        setErrors({ email: "Email Id doesn't exists" });
      }
      setEmailError("An error occurred while sending OTP. Please try again.");
    } finally {
      setSubmitting(false);
    }
  };

  const handleOTPSubmit = async (verifyOtp, setSubmitting) => {
    setLoading(true);
    try {
      const response = await fetch(auth_urls.verify_otp, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId, verifyOtp }),
      });

      if (response.ok) {
        setLoading(false);
        toast.success("OTP verified successfully!");
        setStep("new-password");
      } else {
        setOtpError("Invalid OTP.");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setSubmitting(false);
      setLoading(false);
    }
  };

  const handleNewPasswordSubmit = async (password, setSubmitting) => {
    // setLoading(true);
    try {
      const response = await fetch(auth_urls.change_password, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ userId, password }),
      });

      if (response.ok) {
        // setLoading(false);
        toast.success("Password reset successfully!");
        navigate("/login");
      } else {
        console.error("Error resetting password");
      }
    } catch (error) {
      toast.error("Error: " + error.message);
    } finally {
      setSubmitting(false);
      // setLoading(false);
    }
  };

  useEffect(() => {
    if (emailInputRef.current) {
      emailInputRef.current.focus();
    }
  }, []);

  return (
    <div className="forgot-main-container">
      {loading && (
        <div className="application-loader">
          <div class="lds-ring">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      )}
      <div className="min-h-screen flex flex-col items-center justify-center ">
        <div className="h-[100%] w-full py-8 px-6 bg-[var(--Blue---3)] shadow md:max-w-md">
          <a className="flex justify-center">
            <JobFi />
          </a>
          {(step === "email" || step === "otp") && (
            <p className="pt-[30px]">
              Enter the email address associated with your account and we will
              send you an OTP to reset your password.
            </p>
          )}
          {step === "email" && (
            <Formik
              initialValues={{ email: "" }}
              validationSchema={ForgotPasswordSchema}
              onSubmit={(values, { setSubmitting, setErrors }) => {
                handleEmailSubmit(values.email, { setSubmitting, setErrors });
              }}
            >
              {({ setFieldValue, isSubmitting }) => (
                <Form className="mt-[30px] flex flex-col gap-[30px]">
                  <div>
                    <Field
                      name="email"
                      type="email"
                      innerRef={emailInputRef}
                      className="w-full f-password-email"
                      placeholder="Email address"
                      // onChange={(e)=>{
                      //   setFieldValue(email,e);
                      //   setResponseError(null)
                      // }}
                    />
                    {/* {emailError && (
                      <div className="auth-error-message">{emailError}</div>
                    )} */}

                    <ErrorMessage
                      name="email"
                      component="div"
                      className="auth-error-message"
                    />
                  </div>
                  <button
                    className="text-md font-[700] text-[var(--Blue---2)] cursor-pointer heading-[var(line-height-normal)]
                      w-full h-[40px] rounded text-white bg-[var(--Blue---1)] "
                    style={{ padding: "8px 20px" }}
                    type="submit"
                    disabled={isSubmitting}
                  >
                    Send OTP
                    {/* {loading ? "Sending..." : "Send OTP"} */}
                  </button>
                </Form>
              )}
            </Formik>
          )}

          {step === "otp" && (
            <div>
              {/* <h1>Verify OTP</h1> */}
              <Formik
                initialValues={{ otp: "" }}
                validationSchema={VerifyOTPSchema}
                onSubmit={(values, { setSubmitting }) => {
                  handleOTPSubmit(values.otp, setSubmitting);
                }}
              >
                {({ isSubmitting }) => (
                  <Form className="mt-[30px] flex flex-col gap-[30px]">
                    <div>
                      {/* {otpSent && (
                        <p className="otp-sent">
                          OTP has been sent to your email.
                        </p>
                      )} */}

                      <Field
                        type="text"
                        name="otp"
                        className="w-full f-password-otp"
                        placeholder="Enter OTP"
                      />
                      <ErrorMessage
                        name="otp"
                        component="div"
                        className="auth-error-message"
                      />
                      {otpError && (
                        <div className="auth-error-message">{otpError}</div>
                      )}
                    </div>
                    <button
                      type="submit"
                      className="text-md font-[700] text-[var(--Blue---2)] cursor-pointer heading-[var(line-height-normal)]
                        w-full h-[40px] rounded text-white bg-[var(--Blue---1)] "
                      style={{ padding: "8px 20px" }}
                      disabled={isSubmitting}
                    >
                      Verify OTP
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          )}

          {step === "new-password" && (
            <div>
              <Formik
                initialValues={{ password: "", confirmPassword: "" }}
                validationSchema={NewPasswordSchema}
                onSubmit={(values, { setSubmitting }) => {
                  handleNewPasswordSubmit(values.password, setSubmitting);
                }}
              >
                {({ isSubmitting }) => (
                  <Form className="mt-[30px] flex flex-col gap-[30px]">
                    <div>
                      <Field
                        type="Password"
                        name="password"
                        className="w-full f-password"
                        placeholder="New Password"
                      />
                      <ErrorMessage
                        name="password"
                        component="div"
                        className="auth-error-message"
                      />
                    </div>
                    <div>
                      <Field
                        type="password"
                        name="confirmPassword"
                        className="w-full f-password"
                        placeholder="Confirm Password"
                      />
                      <ErrorMessage
                        name="confirmPassword"
                        component="div"
                        className="auth-error-message"
                      />
                    </div>
                    <button
                      type="submit"
                      className="text-md font-[700] text-[var(--Blue---2)] cursor-pointer heading-[var(line-height-normal)]
                        w-full h-[40px] rounded text-white bg-[var(--Blue---1)]"
                      style={{ padding: "8px 20px" }}
                      disabled={isSubmitting}
                    >
                      Reset Password
                    </button>
                  </Form>
                )}
              </Formik>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
